import withAbortController from '@/utils/withAbortController';
import { ref, watch, shallowRef, computed } from 'vue';
import { axiosInstance } from '@/config/axios';
import delay from '@/utils/delay';
import store from '@/store';

export const useUserFeeds = () => {
    const userFeeds = ref([]);
    const userFeedsIsReady = shallowRef(false);
    const userID = computed(() => store?.getters?.['users/currentUserProfile']?.id);
    const pullUserFeeds = delay(withAbortController((...args) => {
        const queryString = new URLSearchParams({ 'user_id': userID.value }).toString();
        userFeedsIsReady.value = false;
        userFeeds.value = [];
        axiosInstance()
            .then(axios => {
                return axios.get('feeds?'.concat(queryString), {
                    signal: args?.[3]
                });
            })
            .then(response => {
                const data = response?.data?.data;
                if (
                    Array.isArray(data) &&
                    Array.isArray(userFeeds?.value) &&
                    userFeeds?.value?.length === 0
                ) {
                    userFeeds.value = data;
                }
            })
            .finally(() => {
                userFeedsIsReady.value = true;
            });
    }));
    watch(
        userID,
        pullUserFeeds
    );
    return { userFeeds, pullUserFeeds, userFeedsIsReady };
};
