import { exportDocx } from '@/utils/api/export';
import { shallowRef, shallowReactive, computed, watchEffect, watch } from 'vue';
import Papaparse from 'papaparse';
import store from '@/store';
import { useTelegramSearch } from '@/composables/useTelegramSearch';

export const exportEntries = shallowRef([]);
export const exportIsReady = shallowRef(true);
export const exportTypesArray = [
    'text',
    'csv',
    'json',
    'docx'
];
const settings = computed(() => store?.getters?.['users/currentUserProfile']?.settings);

const excludedChats = computed(() => store?.getters?.['telegram/excludedChats']);

watch(excludedChats.value, () => {
    exportEntries.value = exportEntries.value.filter(entry => {
        return excludedChats.value?.[String(entry?.['chat_id'])] === undefined
    });
});

const exportOptions = shallowReactive({
    'highlight_keywords_enabled': settings?.value?.export?.['highlight_keywords_enabled'],
    'highlight_search_enabled': settings?.value?.export?.['highlight_search_enabled'],
    'resize_images_enabled': settings?.value?.export?.['resize_images_enabled']
});
const EXPORT_JSON_TYPE = 'json';
const EXPORT_CSV_TYPE = 'csv';
const EXPORT_TEXT_TYPE = 'text';
const EXPORT_DOCX_TYPE = 'docx';

export const exportData = async (exportType) => {
    const exportTypes = {
        [EXPORT_JSON_TYPE]: 'application/json',
        [EXPORT_CSV_TYPE]: 'text/csv',
        [EXPORT_TEXT_TYPE]: 'text/plain',
        [EXPORT_DOCX_TYPE]: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    };
    let file = '';
    let options = {};
    switch (exportType) {
        case 'csv':
            file = Papaparse?.unparse(exportEntries?.value?.map(entry => ({
                ...entry,
                chat: entry?.chat?.name
            })));
            break;
        case 'json':
            file = JSON.stringify(exportEntries?.value);
            break;
        case 'text':
            file = exportEntries?.value
                ?.map(
                    entry =>
                        `${entry?.['source_group_name']}\n${entry?.date}\n\n${entry?.message}\n\n${entry?.['external_url'] || ''}`
                )
                ?.join(`\n\n———————————\n\n`);
            break;
        case 'docx':
            options = {
                'highlight_keywords_enabled': exportOptions?.['highlight_keywords_enabled']
            }
            if (exportOptions?.['resize_images_enabled']) {
                options['images_fit_width'] = settings?.value?.export?.['images_fit_width'];
                options['images_fit_height'] = settings?.value?.export?.['images_fit_height'];
            }
            if (
                exportOptions?.['highlight_search_enabled'] &&
                useTelegramSearch?.value?.length > 0
            ) {
                options['highlight_search'] = useTelegramSearch.value;
            }
            exportIsReady.value = null;
            try {
                file = await exportDocx(exportEntries?.value?.map(entry => String(entry?.id)), options)
                exportIsReady.value = true;
            } catch (error) {
                console.debug(error);
                exportIsReady.value = false;
            }
            break;
    }

    let element = document.createElement('a');
    if (exportType === 'docx') {
        element?.setAttribute('href', URL.createObjectURL(file));
    } else {
        element?.setAttribute('href', 'data:'.concat(exportTypes?.[exportType], ';charset=utf-8,', encodeURIComponent(file)));
    }
    const localExportType = exportType === 'text' ? 'txt' : exportType;
    element?.setAttribute('download', 'Telegram.export.'?.concat(localExportType));
    element?.click()
};
export const useExport = () => {
    watchEffect(() => {
        if (useTelegramSearch?.value?.length < 4) {
            exportOptions['highlight_search_enabled'] = false;
        } else {
            exportOptions['highlight_search_enabled'] = settings?.value?.export?.['highlight_search_enabled'];
        }
    });
    return {
        exportEntries,
        exportOptions,
        hasExportEntries: computed(() => exportEntries?.value?.length > 0),
        resetExportEntries: () => {
            exportEntries.value = [];
        },
        EXPORT_JSON_TYPE,
        EXPORT_CSV_TYPE,
        EXPORT_TEXT_TYPE,
        EXPORT_DOCX_TYPE
    };
};
