<template>
  <div>
    <div class="my-grid">
      <div>
        <ExportButtons v-if="hasExportEntries" :reset-export-entries="resetExportEntries"></ExportButtons>
      </div>
      <div class="flex">
        <div class="my-h overflow-y-auto overflow-x-hidden px-2 flex-1">
          <div v-for="message in messages" :key="getMessageId(message)"
            class="shadow-lg rounded-lg p-3 mb-5 transition ease-in-out delay-150 hover:shadow-xl duration-300">
            <Message :message="message" :with-message="false" @click="selectMessage(message)">
              <template #checkbox>
                <input v-model="exportEntries" type="checkbox" :value="message"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 cursor-pointer focus:ring-blue-500" />
              </template>
            </Message>
          </div>
          <div class="flex pb-5">
            <UiButton v-if="hasMessages" @click="showMore">
              {{ $i18n('misc.more') }}
            </UiButton>
          </div>
        </div>
        <div class="flex-1 ml-4 overflow-y-auto overflow-x-hidden my-h pr-3">
          <Message v-if="selectedMessage !== null" :message="selectedMessage" @resetSelectedMessage="resetSelectedMessage"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Message from '@/components/Telegram/Message';
import ExportButtons from '@/components/ExportButtons.vue';
import store from '@/store';
import { computed, shallowRef } from 'vue';
import { useExport } from '@/composables/useExport.js'
import UiButton from '@/components/UiButton'

export default {
  components: {
    Message,
    ExportButtons,
    UiButton,
  },
  setup() {
    const { exportEntries, resetExportEntries, hasExportEntries } = useExport();
    const messages = computed(() => store?.getters?.['telegram/messages'])
    const hasMessages = computed(() => store?.getters?.['telegram/messages']?.length > 0)
    const selectedMessage = shallowRef(null)
    return {
      hasExportEntries,
      selectedMessage,
      selectMessage: message => {
        selectedMessage.value = message;
      },
      resetExportEntries,
      exportEntries,
      messages,
      hasMessages,
      messagesReady: computed(() => store?.getters?.['telegram/messagesReady']),
      getMessageId: message => message?.['tg_id'],
      showMore: () => {
        store.commit('telegram/messagesOffset')
      },
      resetSelectedMessage: () => {
        selectedMessage.value = null
      }
    }
  }
}
</script>
<style>
.my-grid {
  display: grid;
  grid-template-columns: 30px auto
}

.my-h {
  max-height: 83vh;
}
</style>
