<template>
    <div>
        <MyDialog :button-name="$i18n('search.add-exclusion')">
            <template #search>
                <div class="relative rounded-md flex-1 mt-5">
                    <input v-model="search" type="text"
                        class="h-12 block w-full rounded-md border-gray-300 pl-3 pr-12 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        :placeholder="$i18n('search.string')" />
                    <div v-if="!chatsReady"
                        class="pointer-events-none absolute inset-y-0 right-2 flex items-center pl-3">
                        <svg class="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                    </div>
                </div>
            </template>
            <div class="flex-1 overflow-y-auto py-6 px-4">
                <div class="mt-8">
                    <div class="flow-root">
                        <ul role="list" class="-my-6 divide-y divide-gray-200">
                            <li v-for="chat in chats" :key="getChatId(chat)" class="flex py-6">
                                <div>
                                    <div
                                        class="flex h-24 w-24 flex-shrink-0 overflow-hidden rounded-full border border-gray-200">
                                        <img v-if="getChatAvatar(chat)" :src="getChatAvatarWithGateway(chat)" :alt="getChatName(chat)"
                                            class="h-full w-full object-cover object-center" />
                                        <span v-else class="m-auto text-sm">{{$i18n('chat.no-avatar')}}</span>
                                    </div>
                                </div>
                                <div class="ml-4 flex flex-1 flex-col">
                                    <div>
                                        <div class="text-base font-medium text-gray-900">
                                            <div class="flex">
                                                <h3 class="font-medium text-xl break-all">
                                                    {{ getChatName(chat) }}
                                                </h3>&nbsp;&nbsp;
                                                <span :key="chatButtonKey(chat)">
                                                    <UnexcludeChatButton v-if="chatExcluded(chat)" :chat="chat" />
                                                    <ExcludeChatButton v-else :chat="chat"  />
                                                </span>
                                            </div>
                                            <div v-if="getChatUsername(chat)" class="text-sm text-gray-600 break-all">
                                                @{{
                                                    getChatUsername(chat)
                                                }} <a v-if="getChatUsername(chat)" target="_blank"
                                                    :href="messageLink(chat)">
                                                    <TelegramIcon class="inline" />
                                                </a></div>
                                            <p class="mt-3 break-all font-light text-base text-gray-600 break-all">{{
                                                getDescription(chat)
                                            }}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </MyDialog>
    </div>
</template>
  
<script>
import { computed, ref } from 'vue'
import store from '@/store';
import gateway from '@/utils/gateway';
import { useChatsRef } from '@/composables/useChatsRef';
import ExcludeChatButton from '@/components/Telegram/ExcludeChatButton'
import MyDialog from '@/components/Dialog'
import TelegramIcon from '@/components/icons/telegram'
import UnexcludeChatButton from '@/components/Telegram/UnexcludeChatButton'

export default {
    components: {
        ExcludeChatButton,
        MyDialog,
        TelegramIcon,
        UnexcludeChatButton
    },
    setup: () => {
        const search = ref('')
        const query = ref({
            search
        })
        const chats = useChatsRef(query)
        const excludedChats = computed(() => store?.getters?.['telegram/excludedChats']);
        const chatExcluded = chat => {
            if (excludedChats?.value?.[chat?.['tg_id']] !== undefined) {
                return true
            }
            return false
        }
        return {
            chats,
            chatsReady: computed(() => store?.getters?.['telegram/chatsReady']),
            getChatId: chat => chat?.['tg_id'],
            getChatName: chat => chat?.name,
            getChatAvatar: chat => chat?.avatar?.['file_location'],
            getChatAvatarWithGateway: chat => gateway(chat?.avatar?.['file_location']),
            getDescription: chat => chat?.description,
            excludeChat: chat => store.commit('telegram/excludeChat', chat),
            getChatUsername: chat => chat?.username,
            messageLink: chat => 'https://t.me/'.concat(chat?.username, '/', chat?.['tg_id']),
            search,
            chatExcluded,
            chatButtonKey: chat => ''.concat(chat?.['tg_id'],chatExcluded(chat))
        }
    }
}
</script>