<template>
    <div v-on="$listeners">
        <div v-if="isOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
        <div v-if="isOpen" class="flex fixed inset-0">
            <div v-on:click="close" class="flex-auto"></div>
            <div class="flex-auto max-w-md flex flex-col h-screen shadow-2xl justify-between bg-white animate-slide">
                <div class="flex gap-5 shadow-md p-5 items-center">
                    <div v-text="title" class="flex-auto"></div>
                    <svg v-on:click="close" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor"
                        class="h-6 w-6 cursor-pointer text-gray-400 hover:opacity-50">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <div class="flex flex-col h-full p-5 overflow-x-hidden">
                    <slot name="main"></slot>
                </div>
                <div class="border-t">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        isOpen: {
            type: Boolean
        }
    },
    emits: ['close'],
    setup: (...args) => {
        const ctx = args?.[1]
        const close = () => {
            ctx.emit('close')
        }
        return {
            close
        }
    }
}
</script>