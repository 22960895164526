<template>
    <button
        v-on="$listeners"
        v-bind="$attrs"
        class="flex w-10 h-11 p-2 border-2 text-white rounded-r-md"
        :class="buttonClass">
        <svg v-if="isOk === null" class="animate-spin m-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
        </svg>
        <slot v-else-if="!isOk">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="m-auto">
                <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>
        </slot>
        <slot v-else name="defaultIcon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg> 
        </slot>
  </button>
</template>
<script>
import { computed } from 'vue'

export default {
    props: {
        isOk: {
            type: [Boolean, null],
            default: true
        }
    },
    setup: props => ({
        isOk: props?.isOk,
        buttonClass: computed(() => {
            if (
                props?.isOk === null ||
                props?.isOk === true
            ) {
                return 'ui-button-blue'
            }
            return 'ui-button-red'
        })
    })
}
</script>
<style>
.ui-button-blue {
    background-color: #2664ec;
    border-color: #2664ec;
}
.ui-button-red {
    background-color: #dc2626;
    border-color: #dc2626;
}
</style>
