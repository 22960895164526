<template>
    <div v-on="$listeners">
        <Tooltip class="inline" :text="$i18n('search.add-exclusion')" position="top">
            <div @click="excludeChat">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6 text-blue-500 cursor-pointer ui-z-index relative">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
        </Tooltip>
    </div>
</template>
<script>
import Tooltip from '@/components/Tooltip';
import store from '@/store';

export default {
    props: {
        chat: {
            type: Object
        }
    },
    emits: ['resetSelectedMessage'],
    components: {
        Tooltip
    },
    setup: (props, ctx) => {
        return {
            text: props?.text,
            excludeChat: () => {
                store.commit('telegram/excludeChat', props?.chat);
                ctx.emit('resetSelectedMessage');
            }
        }
    }
}
</script>
<style>
.ui-z-index {
    z-index: -1;
}
</style>