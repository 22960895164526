<template>
  <div class="flex mt-5">
    <div class="flex-auto"></div>
    <div class="flex-auto w-full max-w-7xl">
      <div class="flex pl-3 mb-5">
        <FilterButton @click="open" class="mr-auto" />
      </div>
      <div class="flex">
        <div class="max-w-md">
          <TelegramFilter v-show="isOpen" />
        </div>
        <div class="flex-auto w-full">
          <TelegramMessages />
        </div>
      </div>
    </div>
    <div class="flex-auto"></div>
  </div>
</template>

<script>
import TelegramFilter from '@/components/Telegram/Filter.vue';
import TelegramMessages from '@/components/Telegram/Messages.vue';
import FilterButton from '@/components/FilterButton.vue';
import { shallowRef, computed } from 'vue';

export default {
  components: {
    TelegramFilter,
    TelegramMessages,
    FilterButton
  },
  setup: () => {
    const isOpen = shallowRef(true)
    return {
      isOpen,
      open: () => {
        if (isOpen.value) {
          isOpen.value = false
        } else {
          isOpen.value = true
        }
      },
      gridClass: computed(() => {
        if (!isOpen.value) {
          return 'grid-cols-1'
        }
        return 'grid-cols-1/3'
      })
    }
  }
}
</script>
