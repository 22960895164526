<template>
  <div>
    <UiButton @click="open = true">
      {{ buttonName }}
    </UiButton>
    <Transition>
      <div v-show="open">
        <div @click="open = false" class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
        <div class="w-1/2 overflow-y-auto h-screen-4/5 bg-white shadow-2xl fixed left-0 top-0 bottom-0 right-0 m-auto">
          <div class="sticky top-0 z-10 p-5 shadow bg-white">
            <div class="flex justify-center">
              <slot name="title">
                <h2 class="text-lg font-medium text-gray-900">{{ $i18n('chats.title') }}</h2>
              </slot>
              <UiClose @click="open = false" class="ml-auto" />
            </div>
            <slot name="search"></slot>
          </div>
          <slot></slot>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { useEscapeKeyRef } from '@/composables/useEscapeKeyRef';
import UiButton from '@/components/UiButton';
import UiClose from '@/components/UiClose';

export default {
  props: {
    buttonName: {
      type: String
    }
  },
  components: {
    UiButton,
    UiClose
  },
  setup() {
    const open = useEscapeKeyRef(false, 1)
    return {
      open
    }
  }
}
</script>
<style scoped>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.my-dialog-height {
  min-height: 80vh;
}
</style>