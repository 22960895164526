import { customRef } from 'vue';

export const useMinStringLength = value => {
    return customRef((track, trigger) => ({
        get: () => {
            track();
            return value;
        },
        set: newValue => {
            if (
                typeof newValue === 'string' &&
                newValue?.length > 3
            ) {
                value = newValue;
                trigger();
            }
        }
    }));
};
