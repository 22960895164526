<template>
  <div class="inline-block">
    <Tooltip>
      <template #text>
        {{ $i18n('post.selected') }}:
        <span class="text-green-400" :key="exportEntriesLength">{{ exportEntriesLength }}</span>
      </template>
      <div class="cursor-pointer p-1 bg-gray-100 rounded">
        <div class="text-center text-sm" :key="exportEntriesLength">{{ exportEntriesLength }}</div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      </div>
    </Tooltip>
    <Tooltip :text="$i18n('post.uncheck-selected')">
      <div @click="resetExportEntries" class="cursor-pointer p-1 bg-gray-100 rounded border-white border-t-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="h-6 w-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </Tooltip>
    <ExportDialog>
      <Tooltip :text="$i18n('post.export')">
        <div class="cursor-pointer p-1 bg-gray-100 rounded border-t-2 border-white">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
          </svg>
        </div>
      </Tooltip>
    </ExportDialog>
  </div>
</template>
<script>
import Tooltip from '@/components/Tooltip';
import ExportDialog from '@/components/ExportDialog.vue';
import { exportEntries } from '@/composables/useExport.js'
import { computed } from 'vue';

export default {
  props: {
    resetExportEntries: {
      type: Function,
      default: () => { }
    }
  },
  components: {
    Tooltip,
    ExportDialog
  },
  setup: props => {
    return {
      resetExportEntries: props?.resetExportEntries,
      exportEntriesLength: computed(() => exportEntries?.value?.length)
    };
  }
};
</script>
