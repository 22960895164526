import { axiosInstance } from '@/config/axios.js';
import { shallowRef, isRef } from 'vue';
import delay from '@/utils/delay.js';

export const useCreateFeedSource = (feedName, sourceName, externalID, userFeeds, sourceType = 'telegram') => {
    const createFeedSourceIsOk = shallowRef(true);
    const createFeedSource = delay(() => {
        createFeedSourceIsOk.value = null;
        axiosInstance()
            .then(axios => {
                let currentFeedName = feedName?.value;
                if (!isRef(feedName)) {
                    currentFeedName = feedName;
                }
                return axios.post('feeds', {
                    name: currentFeedName
                });
            })
            .then(response => {
                if (isRef(userFeeds)) {
                    userFeeds.value = [
                        response?.data,
                        ...userFeeds.value
                    ];
                }
                return response?.data;
            })
            .then(data => {
                return axiosInstance()
                    .then(axios => {
                        return axios.post('feeds/'.concat(data?.id, '/sources'), {
                            name: sourceName,
                            type: sourceType,
                            'external_id': externalID
                        });
                    });
            })
            .then(() => {
                createFeedSourceIsOk.value = true;
            })
            .catch(() => {
                createFeedSourceIsOk.value = false;
            });
    });
    return {
        createFeedSource,
        createFeedSourceIsOk
    };
};
