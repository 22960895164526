import { ref } from 'vue'
import { axiosInstance } from '@/config/axios';
import delay from '@/utils/delay';

export const usePushFeedSource = data => {
    const pushFeedSourceStatus = ref('DEFAULT');
    const pushFeedSource = delay(() => {
        pushFeedSourceStatus.value = 'IN_PROGRESS';
        axiosInstance()
            .then(axios => {
                return axios.post('feeds/'.concat(data?.feedID, '/sources'), {
                    name: data?.sourceName,
                    type: 'telegram',
                    'external_id': data?.externalID
                });
            })
            .then(() => {
                pushFeedSourceStatus.value = 'READY'
            })
            .catch(() => {
                pushFeedSourceStatus.value = 'ERROR'
            })
    });
    return {
        pushFeedSource,
        pushFeedSourceStatus
    }
}