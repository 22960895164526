import withAbortController from '@/utils/withAbortController.js';
import { computed, watch } from 'vue';
import store from '@/store/index.js';
import delay from '@/utils/delay.js';

export const useChatsRef = searchQuery => {
    const pullChats = delay(withAbortController((...args) => {
        const signal = args?.[3];
        const query = args?.[0];
        if (query?.search?.length > 3) {
            store.dispatch('telegram/pullChats', {
                signal,
                query
            });
        }
    }));
    watch(
        searchQuery,
        pullChats,
        { deep: true }
    );

    return computed(() => store?.getters?.['telegram/chats']);
};
