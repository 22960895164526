<template>
    <div v-on="$listeners">
        <Tooltip class="inline" :text="$i18n('search.remove-exclusion')" position="top">
            <div @click="unexcludeChat">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6 text-blue-500 cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
        </Tooltip>
    </div>
</template>
<script>
import Tooltip from '@/components/Tooltip';
import store from '@/store';
export default {
    props: {
        chat: {
            type: Object
        }
    },
    components: {
        Tooltip
    },
    setup: props => {
        return {
            text: props?.text,
            unexcludeChat: () => store.commit('telegram/unexcludeChat', props?.chat)
        }
    }
}
</script>
