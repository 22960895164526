<template>
    <div v-on="$listeners" class="relative flex">
        <div @click="open">
            <slot></slot>
        </div>
        <t-modal v-model="isOpen">
            <div class="flex pt-3">
                <div class="flex px-3 bg-gray-100 rounded-l border border-r-0 border-gray-300 text-sm font-normal">
                    <span class="self-center">
                        {{ $i18n("file.format") }}
                    </span>
                </div>
                <select v-model="currentExportType"
                    class="cursor-pointer font-normal text-sm text-gray-900 border-gray-300 border-l-0 caret-blue-500 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 flex-1">
                    <option v-for="exportType in exportTypesArray" :key="exportType" :value="exportType"> {{
                        exportType.toUpperCase()
                    }}</option>
                </select>
                <UiButtonIconWithStatus :is-ok="exportIsReady" :key="exportIsReady" @click="exportData" class="rounded-l-none rounded-r-md without-shadow">
                    <template #defaultIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-5 h-5 m-auto">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </template>
                </UiButtonIconWithStatus>
            </div>
            <template v-if="hasOptions">
                <label v-for="(option, prop) in exportOptions" :key="prop" class="flex gap-2 m-3">
                    <input v-model="exportOptions[prop]" type="checkbox" :disabled="isDisabled(prop)"
                        class="h-4 w-4 rounded border-gray-300 text-blue-600 cursor-pointer focus:ring-blue-500">
                    <div class="font-normal text-sm text-gray-900 cursor-pointer">{{ $i18n(`export.${prop}`) }}</div>
                </label>
            </template>

        </t-modal>
    </div>
</template>
<script>
import { shallowRef, computed } from 'vue';
import UiButtonIconWithStatus from '@/components/UiButtonIconWithStatus.vue';
import { exportIsReady, exportData, exportTypesArray, useExport } from '@/composables/useExport.js'
import { useTelegramSearch } from '@/composables/useTelegramSearch';

export default {
    components: {
        UiButtonIconWithStatus
    },
    setup: () => {
        const { exportOptions, EXPORT_DOCX_TYPE } = useExport();
        const isOpen = shallowRef(false);
        const currentExportType = shallowRef('text');
        const hasOptions = computed(() => currentExportType?.value === EXPORT_DOCX_TYPE);
        const isDisabled = prop => {
            return (
                prop === 'highlight_search_enabled' &&
                useTelegramSearch?.value?.length < 4
            );
        };
        const open = () => {
            if (!isOpen.value) {
                isOpen.value = true;
            } else {
                isOpen.value = false;
            }
        };
        return {
            isOpen,
            open,
            exportIsReady,
            exportTypesArray,
            currentExportType,
            exportOptions,
            exportData: () => {
                exportData(currentExportType?.value)
            },
            hasOptions,
            useTelegramSearch,
            isDisabled
        };
    }
}
</script>
<style>
.my-button {
    border-left: 0;
}
.without-shadow {
    box-shadow: none !important;
}
</style>