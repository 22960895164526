import gateways from '@/config/gateways';

let defaultGateway = gateways?.default

if (
  typeof defaultGateway === 'string' &&
  defaultGateway.endsWith('/')
) {
  defaultGateway = defaultGateway.substring(0, defaultGateway.length - 1)
}

export default (value, path = 'tgcp') => {
    if (
      typeof value === 'string' &&
      value.match(/\s*https?:\/\//) !== null
      ) {
      return value
    }
    const hostname = location.hostname
    const gateway = gateways?.[hostname]
    if (typeof gateway === 'string') {
      let current = gateway.concat('/', path, '/')
      if (current.endsWith('/')) {
        current = current.substring(0, current.length - 1)
      }
      return current.concat(value)
    }
    return ''.concat(defaultGateway.concat('/', path, '/'), value)
  }
  