import { shallowRef, onMounted, onUnmounted } from 'vue';

const data = shallowRef([]);

export const useEscapeKeyRef = (value, key) => {
    data.value[key] = shallowRef(value);
    const onEscape = event => {
        if (
            data?.value?.at(-1)?.value === true &&
            key !== (data?.value?.length - 1)
        ) return;
        if (event?.key === 'Escape') {
            data.value[key].value = false;
        }
    }
    onMounted(() => {
        document.addEventListener('keydown', onEscape);
    });
    onUnmounted(() => {
        document.removeEventListener('keydown', onEscape);
    });
    return data.value[key];
};
