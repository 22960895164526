<template>
    <div v-on="$listeners">
        <div @click="open">
            <slot></slot>
        </div>
        <t-modal v-model="isOpen">
            <div :key="isOpen" class="bg-white">
                <template>
                    <div v-if="!userFeedsIsReady" class="flex text-blue-500 mt-5">
                        <svg class="animate-spin m-auto w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                    </div>
                    <template v-else>
                        <div class="overflow-y-auto">
                            <AddToFeedCard v-for="userFeed in userFeeds" :key="getFeedID(userFeed)" :feed="userFeed"
                                :chat="chat" />
                            <div v-if="!createFeedSourceIsOpen" @click="openCreateFeedSource" class="flex m-2 p-2 break-all text-normal font-semibold text-blue-600 underline cursor-pointer">
                                <span>{{ $i18n('feed.create') }}</span>
                            </div>
                        </div>
                    </template>
                </template>
                <div v-if="createFeedSourceIsOpen" class="flex p-3">
                    <input v-model="feedName" type="text" :placeholder="$i18n('feed.name')"
                        class="h-11 rounded-l-md border-gray-300 caret-blue-500 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 flex-1">
                    <UiButtonIconWithStatus :is-ok="createFeedSourceIsOk" :key="createFeedSourceIsOk"
                        @click="createFeedSource" />
                </div>
            </div>
        </t-modal>
    </div>
</template>
<script>
import store from '@/store/index.js';
import { shallowRef } from 'vue';
import { useUserFeeds } from '@/composables/useUserFeeds.js';
import { useCreateFeedSource } from '@/composables/useCreateFeedSource.js';
import { useMinStringLength } from '@/composables/useMinStringLength.js';
import UiButtonIconWithStatus from '@/components/UiButtonIconWithStatus.vue';
import AddToFeedCard from '@/components/Telegram/AddToFeedCard.vue';

export default {
    props: {
        chat: {
            type: Object
        }
    },
    components: {
        UiButtonIconWithStatus,
        AddToFeedCard
    },
    setup: props => {
        const feedName = useMinStringLength('');
        const isOpen = shallowRef(false);
        const { userFeeds, pullUserFeeds, userFeedsIsReady } = useUserFeeds();
        const unexcludeChat = () => store.commit('telegram/unexcludeChat', props?.chat);
        const createFeedSourceIsOpen = shallowRef(false);
        const open = () => {
            if (!isOpen.value) {
                isOpen.value = true;
                unexcludeChat();
                pullUserFeeds();
            } else {
                isOpen.value = false;
            }
        };
        const {
            createFeedSource,
            createFeedSourceIsOk
        } = useCreateFeedSource(feedName, props?.chat?.name, String(props?.chat?.['tg_id']), userFeeds);
        return {
            isOpen,
            open,
            userFeedsIsReady,
            userFeeds,
            feedName,
            chat: props?.chat,
            getFeedID: feed => feed?.id,
            unexcludeChat,
            createFeedSource,
            createFeedSourceIsOk,
            createFeedSourceIsOpen,
            openCreateFeedSource: () => {
                createFeedSourceIsOpen.value = true
            }
        };
    }
}
</script>
