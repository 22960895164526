<template>
    <div>
        <Offcanvas :is-open="isOpen" :title="$i18n('search.excluded')" @close="close">
            <template #main>
                <div v-for="chat in chats" :key="getChatId(chat)" class="flex py-6 gap-3">
                    <div class="flex h-24 w-24 flex-shrink-0 overflow-hidden rounded-full border border-gray-200">
                        <img v-if="getChatAvatar(chat)" :src="getChatAvatarWithGateway(chat)" :alt="getChatName(chat)"
                            class="h-full w-full object-cover object-center" />
                        <span v-else class="m-auto text-sm">{{ $i18n('chat.no-avatar') }}</span>
                    </div>
                    <div class="flex flex-1 flex-col">
                        <div>
                            <div class="text-base font-medium text-gray-900">
                                <div class="flex">
                                    <h3 class="font-medium text-xl break-all">
                                        {{
                                            getChatName(chat)
                                        }}
                                    </h3>&nbsp;&nbsp;
                                    <UnexcludeChatButton :chat="chat" />
                                </div>
                                <div class="text-sm text-gray-600 break-all">@{{
                                    getChatUsername(chat)
                                }} <a v-if="getChatUsername(chat)" target="_blank" :href="messageLink(chat)">
                                        <TelegramIcon class="inline" />
                                    </a></div>
                                <p class="mt-3 font-light break-all">{{
                                    getDescription(chat)
                                }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </template>
            <template #footer>
                <div class="flex mt-6">
                    <t-pagination v-if="excludedChatsCounter > 0" v-model="page" :per-page="chatsLimit"
                        :total-items="excludedChatsCounter" variant="rounded" class="mx-auto" />
                </div>
                <div class="p-5">
                    <ChatList />
                </div>
            </template>
        </Offcanvas>
        <UiButton @click="open">
            {{ $i18n(`search.${buttonName}`) }}
        </UiButton>
    </div>
</template>
  
<script>
import { ref, computed } from 'vue'
import store from '@/store'
import ChatList from './ChatList'
import UiButton from '@/components/UiButton'
import gateway from '@/utils/gateway';
import UnexcludeChatButton from '@/components/Telegram/UnexcludeChatButton'
import TelegramIcon from '@/components/icons/telegram'
import Offcanvas from '@/components/Offcanvas.vue'
import { useEscapeKeyRef } from '@/composables/useEscapeKeyRef';

export default {
    components: {
        ChatList,
        UiButton,
        UnexcludeChatButton,
        TelegramIcon,
        Offcanvas
    },
    setup: () => {
        const chatsLimit = computed(() => store?.getters?.['telegram/chatsLimit'])
        const page = ref(1)
        const isOpen = useEscapeKeyRef(false, 0)
        const excludedChatsCounter = computed(() => store.getters['telegram/excludedChatsCounter'])
        const buttonName = computed(() => {
            if (excludedChatsCounter.value === 0) {
                return 'no-excluded'
            }
            return String(excludedChatsCounter.value).concat('ChannelsExcluded')
        })
        const chats = computed(() => {
            const localPage = page.value
            const limit = chatsLimit.value
            let offsetStart = 0
            let offsetEnd = limit
            offsetStart = limit * (localPage - 1)
            offsetEnd = limit * localPage
            return Object.values(store?.getters?.['telegram/excludedChats']).slice(offsetStart, offsetEnd)
        })
        return {
            chats,
            isOpen,
            open: () => {
                isOpen.value = true
            },
            close: () => {
                isOpen.value = false
            },
            getChatId: chat => chat?.['tg_id'],
            getChatName: chat => chat?.name,
            getChatAvatar: chat => chat?.avatar?.['file_location'],
            getChatAvatarWithGateway: chat => gateway(chat?.avatar?.['file_location']),
            getDescription: chat => chat?.description,
            unexcludeChat: chat => store.commit('telegram/unexcludeChat', chat),
            buttonName,
            excludedChatsCounter,
            chatsLimit,
            page,
            getChatUsername: chat => chat?.username,
            messageLink: chat => 'https://t.me/'.concat(chat?.username, '/', chat?.['tg_id']),
        }
    }
}
</script>
<style>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>