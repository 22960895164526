import withAbortController from '@/utils/withAbortController.js';
import currentDate from '@/utils/currentDate.js';
import { computed, watch, ref } from 'vue';
import store from '@/store/index.js';
import delay from '@/utils/delay.js';

export const useMessagesRef = searchQuery => {
    const offset = ref(0);
    const pullMessages = delay(withAbortController((...args) => {
        const signal = args?.[3];
        const query = args?.[0];
        if (query?.search?.length > 3) {
            let localQuery = {};
            if (offset.value === query?.offset) {
                localQuery.offset = 0;
                offset.value = 0;
                store?.commit('telegram/resetMessagesOffset');
            } else {
                localQuery.offset = query?.offset;
                offset.value = query?.offset;
            }
            if (query?.['from_date']) {
                localQuery['from_date'] = currentDate(query?.['from_date']);
            }
            if (query?.['to_date']) {
                localQuery['to_date'] = currentDate(query?.['to_date']);
            }
            if (query?.['exclude_chat_ids']?.length) {
                localQuery['exclude_chat_id'] = query?.['exclude_chat_ids'];
            }
            if (query?.['order_type']?.length) {
                localQuery['order_type'] = query?.['order_type'];
            }
            if (query?.['order_by']?.length) {
                localQuery['order_by'] = query?.['order_by'];
            }
            store.dispatch('telegram/pullMessages', {
                signal,
                query: {
                    search: query?.search,
                    ...localQuery
                }
            });
        }
    }));
    watch(
        searchQuery,
        pullMessages,
        { deep: true }
    );
    return computed(() => store?.getters?.['telegram/messages']);
};
