<template>
  <div v-on="$listeners">
    <div>
      <template>
        <template v-if="withMessage">
          <div class="flex items-center text-sm text-gray-500 pb-2 gap-2">
            <template>
              <a v-if="getChatUsername(message)" target="_blank" :href="messageLink(message)"
                class="break-all text-right">
                <TelegramIcon class="inline" />
              </a>
              <TelegramIcon v-else class="inline" />
            </template>
            <div class="flex-1"></div>
            <div v-if="!withMessage" class="flex-1 text-sm">{{ getMessageDate(message) }}</div>
            <template v-if="withMessage">
              <AddToFeed v-if="open" :chat="getChat(message)" :key="getChatId(message)">
                <Tooltip class="inline" :text="$i18n('feed.add')" position="right">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6 text-blue-500 cursor-pointer ui-z-index relative">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </Tooltip>
              </AddToFeed>
              <ExcludeChatButton @resetSelectedMessage="resetSelectedMessage" v-if="!excludedChat" :chat="getChat(message)" :key="getMessageId(message)" />
              <a :href="file(message)" :download="sourceGroupName(message)" class="ml-auto text-blue-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline w-6 h-6" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
              </a>
              <a v-if="getMessageExternalUrl(message)" :href="getMessageExternalUrl(message)"
                :key="getMessageExternalUrl(message)" target="_black" rel="noopener noreferrer" class="text-blue-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="inline w-6 h-6" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="flex gap-5 items-center text-sm text-gray-500 text-right break-all pb-2">
            <slot name="checkbox"></slot>
            <div class="flex-1 content-end text-sm">{{ getMessageDate(message) }}</div>
            <template>
              <a v-if="getChatUsername(message)" target="_blank" :href="messageLink(message)">
                <TelegramIcon class="inline" />
              </a>
              <TelegramIcon v-else class="inline" />
            </template>
          </div>
        </template>
      </template>
      <div class="text-sm mb-2">
        <div class="flex gap-5 items-center">
          <template>
            <template v-if="withMessage">
              <i class="flex-1 text-lg font-normal text-gray-700 break-all overflow-x-auto">{{
                getChatName(message)
              }}</i>
              <div class="text-sm">{{ getMessageDate(message) }}</div>
            </template>
            <template v-else>
              <i class="flex-1 text-lg font-normal text-gray-700 break-all overflow-x-auto">{{
                getChatName(message)
              }}</i>
            </template>
          </template>
        </div>
      </div>
      <template>
        <template v-if="withMessage">
          <p v-html="getMessage(message)" class="text-sm break-all overflow-x-auto my-links"></p>
        </template>
        <template v-else>
          <p v-html="getMessageTitle(message)" class="text-normal font-medium text-gray-900 break-all overflow-x-auto"
            :class="messageLimit">
          </p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import xss from 'xss';
import gateway from '@/utils/gateway';
import formatHtml from '@/utils/formatHtml';
import TelegramIcon from '@/components/icons/telegram'
import AddToFeed from '@/components/Telegram/AddToFeed'
import ExcludeChatButton from '@/components/Telegram/ExcludeChatButton'
import { shallowRef, computed } from 'vue'
import store from '@/store';
import Tooltip from '@/components/Tooltip';

export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    withMessage: {
      type: Boolean,
      default: true
    }
  },
  emits: ['resetSelectedMessage'],
  components: {
    TelegramIcon,
    AddToFeed,
    ExcludeChatButton,
    Tooltip
  },
  setup: (props, ctx) => {
    const isOpen = shallowRef(false)
    const formatDate = value => {
      const dateInstance = new Date(value);
      return dateInstance
        .toLocaleDateString()
        .concat(' ', dateInstance.toLocaleTimeString())
    }
    return {
      isOpen,
      open: () => {
        isOpen.value = true
      },
      close: () => {
        isOpen.value = false
      },
      getMessage: message => formatHtml(xss(message?.message)),
      getMessageTitle: message => xss(message?.title),
      getMessageId: message => message?.['tg_id'],
      getMessageExternalUrl: message => message?.['external_url'],
      getChatName: message => message?.chat?.name,
      sourceGroupName: message => message?.['source_group_name'],
      getChatUsername: message => message?.chat?.username,
      getChat: message => message?.chat,
      getChatId: message => message?.chat?.['tg_id'],
      getMessageDate: message => formatDate(message?.date),
      getChatAvatarWithGateway: message => gateway(message?.chat?.avatar?.['file_location']),
      messageLink: message => 'https://t.me/'.concat(message?.chat?.username, '/', message?.chat?.['tg_id']),
      excludedChat: computed(() => store.getters['telegram/excludedChatIds'].includes(props?.message?.chat?.['tg_id'])),
      messageLimit: computed(() => {
        if (!props?.withMessage) {
          return 'line-clamp-3'
        }
        return ''
      }),
      file: message => {
        return `data:text/html,${encodeURIComponent(xss(message.message))}`;
      },
      resetSelectedMessage: () => {
        ctx.emit('resetSelectedMessage')
      }
    }
  }
}
</script>
<style>
.my-pr {
  padding-right: 2px;
}

.my-links a {
  text-decoration: underline !important;
}
.ui-z-index {
  z-index: -1;
}
</style>