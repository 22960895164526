<template>
    <div>
        <div @click="pushFeedSource"
            class="flex m-2 p-2 break-all rounded-md border border-gray-200 hover:outline-none hover:border-blue-500 hover:ring-blue-500 hover:ring-1 cursor-pointer">
            <span class="flex-1 text-sm font-medium break-all">
                {{ feedName }}
            </span>
            <UiButtonIconWithCheck :status="pushFeedSourceStatus" :key="pushFeedSourceStatus"/>
        </div>
    </div>
</template>
<script>
import { usePushFeedSource } from '@/composables/usePushFeedSource.js';
import UiButtonIconWithCheck from '@/components/UiButtonIconWithCheck.vue';
import { computed } from 'vue'

export default {
    props: {
        feed: {
            type: Object
        },
        chat: {
            type: Object
        }
    },
    components: {
        UiButtonIconWithCheck
    },
    setup: props => {
        const {
            pushFeedSource,
            pushFeedSourceStatus
        } = usePushFeedSource({
            feedID: props?.feed?.id,
            sourceName: props?.chat?.name,
            externalID: String(props?.chat?.['tg_id'])
        })
        return {
            feedName: computed(() => props?.feed?.name)?.value,
            pushFeedSource,
            pushFeedSourceStatus
        }
    }
}
</script>