<template>
  <div class="flex flex-col gap-y-3 px-3 w-full">
    <h1 class="text-lg font-base text-gray-700 leading-6">{{ $i18n('search.general') }} - Telegram</h1>
    <div class="relative rounded-md flex-1">
      <input v-model="search" type="text"
        class="block my-height w-full rounded-md border-gray-300 pl-3 pr-12 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
        :placeholder="$i18n('search.string')" />
      <div v-if="!messagesReady" class="pointer-events-none absolute inset-y-0 right-2 flex items-center pl-3">
        <svg class="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
      </div>
    </div>
    <div class="flex justify-between gap-1">
        <div>
          <select
            v-model="orderBy"
            id="order_by"
            name="order_by"
            class="block py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm my-w"
          >
            <option
              v-for="value in defaultOptions.order_by"
              :value="value"
              :key="value"
              >{{ $i18n(`filter.${value}`) }}</option
            >
          </select>
        </div>
        <div>
          <select
            v-model="orderType"
            id="order_type"
            name="order_type"
            class="block w-full py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm my-w"
          >
            <option
              v-for="value in defaultOptions.order_type"
              :value="value"
              :key="value"
              >{{ $i18n(`filter.${value}`)  }}</option
            >
          </select>
        </div>
      </div>
    <div class="flex gap-3">
        <div class="p-2 bg-gray-100 rounded">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
        </div>
        <t-datepicker v-model="dateAfter" user-format="d.m.Y" date-format="Z" :close-on-select="true" />
        <t-datepicker v-model="dateBefore" date-format="Z" user-format="d.m.Y" :close-on-select="true" />
    </div>
    <ExcludedChats />
    <div class="relative rounded-md flex-1">
      <select v-model="selected_source" @change="feedSelected"
        class="block my-height w-full rounded-md border-gray-300 pl-3 pr-12 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
        :placeholder="$i18n('search.string')" >
           <option value="0" >{{ $i18n('feed.filter') }}</option>
           <option v-for="source in sourceArray" :key="source.id" :value="source.id"> {{
                        source.name
                    }}</option>
      </select>      
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import store from '@/store';
import ExcludedChats from '@/components/Telegram/ExcludedChats';
import { useMessagesRef } from '@/composables/useMessagesRef';
import { useTelegramSearch } from '@/composables/useTelegramSearch';

export default {
  components: {
    ExcludedChats
  },
  mounted() {    
    store.dispatch('feeds/fetchFeeds', {feedsLimit: 100, query: {order_by: 'name', limit: 100, order_type: 'ASC'}})
  },
  methods: {
    feedSelected() {        
        store.commit('telegram/selectFeed', this.selected_source);
    }
  },
  setup() {
    
    const excludedChats = computed(() => store.getters['telegram/excludedChats'])
    const excludedChatIds = computed(() => store.getters['telegram/excludedChatIds'])
    const excludedChatsCounter = computed(() => store.getters['telegram/excludedChatsCounter'])
    const sourceArray =  computed(() => store?.getters?.['feeds/feeds']?.data)
    const buttonName = computed(() => {
      if (excludedChatsCounter.value === 0) {
        return 'thereAreNoExcludedChannels'
      }
      return String(excludedChatsCounter.value).concat('ChannelsExcluded')
    })
    const search = useTelegramSearch
    const orderBy = ref("date");
    const orderType = ref("desc");
    const defaultOptions = ref({
      order_by: ["relevancy", "date", "chat"],
			order_type: ["asc", "desc"],
    })
    const dateAfter = ref(null)
    const dateBefore = ref(null)
    const offset = computed(() => store?.getters?.['telegram/messagesOffset'])
    let selected_source = ref(0)
    const query = ref({
      search,
      selected_source,
      'exclude_chat_ids': excludedChatIds,
      'from_date': dateAfter,
      'to_date': dateBefore,
      offset,
      "order_by" : orderBy,
      "order_type" : orderType
    })
    
    useMessagesRef(query)
    return {
      search,      
      getChatId: chat => chat?.['tg_id'],
      excludedChats,
      buttonName,
      sourceArray,
      selected_source,
      messagesReady: computed(() => store?.getters?.['telegram/messagesReady']),
      dateAfter,
      dateBefore,
      orderBy,
      orderType,
      defaultOptions
    };
  }
}
</script>
<style>
.my-height {
  height: 42px;
}
</style>
